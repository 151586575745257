<template>
  <div>
    <k-header-card class="pb-0">
      <div class="d-flex align-items-center">
        <div
          class="w-100 d-flex justify-content-between header-with-save-button"
        >
          <div class="w-50 d-flex flex-column align-items-start">
            <span
              class="
                text-primary
                d-flex
                align-items-center
                font-work-sans
                cursor-pointer
              "
              @click="$router.push({ path: `/order-details/${orderId}` })"
            >
              <feather-icon
                class="me-1"
                icon="ChevronLeftIcon"
              /> Back To Order Summary
            </span>
            <span
              v-if="order"
              class="kp-text-color font-inter kp-id"
            >
              Order ID: {{ order.orderSeqId || order._id }}
            </span>
          </div>
          <div class="w-50 d-flex justify-content-end align-items-center">
            <!-- Save Changes button for order details tab -->
            <k-button
              v-if="isOrderDetailsTab"
              variant="info"
              :disabled="isSaving"
              @click="saveChanges"
            >
              <loader-icon v-if="isSaving" />
              <save-icon
                v-else
                class="me-1"
              />
              SAVE CHANGES
            </k-button>
          </div>
        </div>
      </div>
      <div
        v-if="navBarItems"
        class="d-flex tabs"
      >
        <span class="font-inter tab-header">Order Management Step: </span>
        <b-link
          v-for="navItem in navBarItems"
          :key="navItem.text"
          class="tab-item d-flex flex-row font-inter"
          :active="navItem.isActive"
          :disabled="navItem.isActive"
          @click="onClickTab(navItem)"
        >
          <span
            class="p-1 me-1"
            v-html="navItem.icon"
          />
          <span>{{ navItem.text }}</span>
        </b-link>
      </div>
    </k-header-card>

    <div class="kp-body-container">
      <router-view @on-updated="onSaved" />
    </div>
  </div>
</template>

<script>
import KHeaderCard from '@/@core/components/KHeaderCard.vue'
import orderManagementTabs from '@/assets/app-content/order-management-tabs.json'
import SaveIcon from '@/assets/images/svg/SaveIcon.vue'
import FeatherIcon from '@/components/feather-icon/FeatherIcon.vue'
import { ROUTES } from '@/constants'
import KButton from '@kingpin-global/kingpin-ui/src/components/button/Button.vue'
import { LoaderIcon } from 'vue-feather-icons'

export default {
  name: 'OrderManagement',
  components: {
    FeatherIcon,
    SaveIcon,
    KButton,
    LoaderIcon,
    KHeaderCard,
  },
  data() {
    return {
      subNavBarItems: orderManagementTabs.subNavBarItems,
      navBarItems: null,
      orderId: this.$route.params.orderId,
      isSaving: false,
    }
  },
  computed: {
    order() {
      return this.$store.getters.getEditingOrder
    },
    isOrderDetailsTab() {
      return this.$route.name === ROUTES.ORDER_MANAGEMENT.CHILDREN.ORDER_DETAILS_TAB.name
    },
  },
  created() {
    this.navBarItems = this.subNavBarItems.map(navBarItem => ({
      ...navBarItem,
      isActive: this.$route.name === navBarItem.router.name,
    }))
  },
  methods: {
    onClickTab(navItem) {
      this.navBarItems.forEach(navBarItem => {
        navBarItem.isActive = navItem.text === navBarItem.text
      })
      this.$router.push(navItem.router)
    },
    saveChanges() {
      this.isSaving = true
      this.successErrorMessage = null
      this.$root.$refs.OrderDetailsTab.updateOrder()
    },
    onSaved() {
      this.isSaving = false
    },
  },
}
</script>

<style lang="scss">
@import "@/@core/scss/vue/pages/kp-management.scss";
</style>
